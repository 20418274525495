@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
}

@font-face {
	font-display: swap;
	font-family: 'TTNormsPro';
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-style: normal;
	font-weight: 125 950;
	src: url(/fonts/TTNormsPro-Var-Roman.ttf) format('truetype'); /* Safari, Android, iOS */
}

@layer components {
	.faq-collection,
	.faq-item {
		@apply flex flex-col;
	}
	.faq-title {
		@apply font-medium;
	}
	.text-dark-med {
		@apply text-gray-600 dark:text-gray-300;
	}
	.text-dark-high {
		@apply text-gray-900 dark:text-gray-100;
	}
	.text-light-med {
		@apply text-gray-200 dark:text-gray-200;
	}
	.text-light-high {
		@apply text-gray-100 dark:text-gray-100;
	}
	.surface-light {
		@apply bg-white dark:bg-gray-900;
	}
	.surface-neutral {
		@apply bg-gray-50 dark:bg-gray-800;
	}
	.text-headline1 {
		@apply tracking-tighter text-7xl;
	}
	.text-headline2 {
		@apply text-6xl tracking-tighter;
	}
	.text-headline3 {
		@apply text-5xl tracking-normal;
	}
	.text-headline4 {
		@apply text-4xl tracking-normal;
	}
	.text-headline5 {
		@apply text-3xl tracking-normal;
	}
	.text-headline6 {
		@apply text-2xl tracking-normal;
	}
	.text-subtitle1 {
		@apply text-xl;
	}
	.text-subtitle2 {
		@apply text-lg tracking-normal;
	}
	.text-body2 {
		@apply text-sm text-gray-600 dark:text-gray-300;
	}
	.text-caption {
		@apply text-xs;
	}
	.text-overline {
		@apply text-xs font-semibold tracking-widest uppercase;
	}
	.text-link {
		@apply underline cursor-pointer text-primary-600;
	}
	.menu-item-link {
		@apply text-gray-300 transition-colors text-caption hover:bg-gray-50 dark:hover:bg-gray-800 hover:text-gray-900 dark:hover:text-gray-50;
	}
	.icon-sm {
		@apply w-4 h-4;
	}
	.icon-base {
		@apply w-6 h-6;
	}
}
